import { AmountPerEnergyTypeGroup } from '@boosterfuels/types/types-ts/embedded/AmountPerEnergyTypeGroup.model'
import { findIndex, sumBy } from 'lodash'
import {
  AVERAGE_TIME_TO_FROM_GAS_STATION,
  DRIVER_HOURLY_WAGE,
  EMISSIONS_REDUCED_PER_GALLON_OF_RENEWABLES,
  EMISSIONS_REDUCED_PER_MILE_NOT_TRAVELED,
  EMPLOYEES_PER_VEHICLE,
  MILES_PER_ROUND_TRIP,
  MISUSE_AND_THEFT_RATE,
  WEAR_AND_TEAR_PER_MILE,
  WEAR_AND_TEAR_PER_MILE_NEW,
} from '@boosterfuels/types/util/costSavingsConstants'
import { DealProduct } from '@boosterfuels/types/types-ts'

export const addToAmountPerEnergyTypeGroup = (
  aggregation: AmountPerEnergyTypeGroup[],
  amount: number,
  energyTypeGroup: string,
  useTotal: boolean
): AmountPerEnergyTypeGroup[] => {
  const objIndex: number = findIndex(
    aggregation,
    (a) => a.energyTypeGroup === energyTypeGroup
  )
  if (objIndex !== -1) {
    aggregation[objIndex]['amount'] += amount
  } else {
    aggregation.push({ amount, energyTypeGroup })
  }

  if (useTotal) {
    const totalIndex: number = findIndex(
      aggregation,
      (a) => a.energyTypeGroup === 'TOTAL'
    )
    if (totalIndex !== -1) {
      aggregation[totalIndex]['amount'] += amount
    } else {
      aggregation.push({ amount, energyTypeGroup: 'TOTAL' })
    }
  }

  return aggregation
}

export const calculateCostsSaved = (
  labor: number,
  wearAndTear: number,
  misuseAndTheft: AmountPerEnergyTypeGroup[]
) => {
  const totalMisuseAndTheft: number =
    sumBy(misuseAndTheft, (m) => {
      return m.energyTypeGroup !== 'TOTAL' ? m.amount : 0
    }) || 0

  return labor + wearAndTear + totalMisuseAndTheft
}

export const calculateMilesReduced = (
  vehiclesFueled: number,
  milesToGasStation: number
) => {
  return vehiclesFueled * (milesToGasStation || MILES_PER_ROUND_TRIP)
}

export const calculateEmissionsPreventedRenewables = (
  gallonsDispensedRenewables: number
) => {
  return gallonsDispensedRenewables * EMISSIONS_REDUCED_PER_GALLON_OF_RENEWABLES
}

export const calculateEmissionsPrevented = (
  milesReduced: number,
  gallonsDispensedRenewables: number
) => {
  return (
    milesReduced * EMISSIONS_REDUCED_PER_MILE_NOT_TRAVELED +
    calculateEmissionsPreventedRenewables(gallonsDispensedRenewables || 0)
  )
}

export const calculateHoursSaved = (
  vehiclesFueled: number,
  employeesPerVehicle: number,
  averageTimeToGasStation: number
) => {
  const roundtripTime = averageTimeToGasStation
    ? averageTimeToGasStation / 60 // this is stored in minutes
    : AVERAGE_TIME_TO_FROM_GAS_STATION

  return (
    vehiclesFueled *
    (employeesPerVehicle || EMPLOYEES_PER_VEHICLE) *
    roundtripTime
  )
}

export const calculateLabor = (
  vehiclesFueled: number,
  employeesPerVehicle: number,
  averageTimeToGasStation: number,
  driverHourlyWage: number
) => {
  return (
    vehiclesFueled *
    calculateLaborPerTrip(
      employeesPerVehicle,
      averageTimeToGasStation,
      driverHourlyWage
    )
  )
}

export const calculatedCombinedLaborCost = (
  employeesPerVehicle: number,
  driverHourlyWage: number
) => {
  return (
    (employeesPerVehicle || EMPLOYEES_PER_VEHICLE) *
    (driverHourlyWage || DRIVER_HOURLY_WAGE)
  )
}

export const calculateLaborPerTrip = (
  employeesPerVehicle: number,
  averageTimeToGasStation: number,
  driverHourlyWage: number
) => {
  const roundtripTime = averageTimeToGasStation
    ? averageTimeToGasStation / 60
    : AVERAGE_TIME_TO_FROM_GAS_STATION

  return (
    calculatedCombinedLaborCost(employeesPerVehicle, driverHourlyWage) *
    roundtripTime
  )
}

export const calculateMisuseAndTheft = (
  vehiclesFueled: number,
  averagePricePerGallon: number
) => {
  return MISUSE_AND_THEFT_RATE * vehiclesFueled * averagePricePerGallon
}

export const calculateMisuseAndTheftGallons = (
  gallons: number,
  averagePricePerGallon: number,
  totalTripsPerYear: number,
  misuseAndTheftRate?: number
) => {
  return (
    (misuseAndTheftRate ?? MISUSE_AND_THEFT_RATE) *
    gallons *
    averagePricePerGallon *
    totalTripsPerYear
  )
}

// ! this is still using the old wear and tear constant
export const calculateWearAndTear = (
  vehiclesFueled: number,
  milesToGasStation: number
) => {
  return (
    vehiclesFueled *
    (milesToGasStation || MILES_PER_ROUND_TRIP) *
    WEAR_AND_TEAR_PER_MILE
  )
}

export const calculateWearAndTearPerMile = (milesToGasStation: number) => {
  return (
    (milesToGasStation || MILES_PER_ROUND_TRIP) * WEAR_AND_TEAR_PER_MILE_NEW
  )
}

export function totalVehicleTripsPerWeek(
  numberOfVehicles: number,
  tripsPerWeek: number
) {
  return numberOfVehicles * tripsPerWeek
}

export function calculateTotalTripsPerYear(tripsPerWeek: number) {
  return 52 * tripsPerWeek
}

export function calculateAnnualLaborCost(
  costPerTrip: number,
  totalTripsPerYear: number
) {
  return costPerTrip * totalTripsPerYear
}

export function calculateAnnualAdminCosts(
  hourlyWage: number,
  hoursPerWeek: number
) {
  return 52 * hourlyWage * hoursPerWeek
}

export function calculateAnnualWearAndTear(
  milesToGasStation: number,
  tripsPerYear: number
) {
  return milesToGasStation * tripsPerYear * WEAR_AND_TEAR_PER_MILE_NEW
}

export function calculateTotalVehicles(products: DealProduct[]) {
  return products.reduce((sum, vehicle) => {
    return sum + vehicle.numberOfAssets
  }, 0)
}

export function calculateCustomerVehicleCost(
  costPerTrip: number,
  wearAndTear: number,
  misuseAndTheft: number
) {
  return costPerTrip + wearAndTear + misuseAndTheft
}

export function calculateBoosterVehiclePricing(
  visitFee: number,
  numberOfVehicles: number,
  vehicleFee?: number
) {
  if (vehicleFee != null) {
    return visitFee / numberOfVehicles + vehicleFee
  }

  return visitFee / numberOfVehicles
}

export function calculateBoosterVehicleSavings({
  gallonsPerVehicle,
  pricingDiscount,
  savings,
}: {
  gallonsPerVehicle: number
  pricingDiscount?: number
  savings?: number
}) {
  if (savings == null && pricingDiscount == null) {
    throw new Error('There needs to be savings or a pricing discount.')
  }

  return pricingDiscount != null
    ? pricingDiscount * gallonsPerVehicle
    : (savings as number) / gallonsPerVehicle
}

export function calculateBoosterVehicleTotalSavings(
  customerCostPerVehicle: number,
  vehiclePricing: number,
  vehicleSavings: number
) {
  return customerCostPerVehicle - (vehiclePricing - vehicleSavings)
}

export function calculateCustomerFleetCost(
  customerCostPerVehicle: number,
  numberOfVehicles: number
) {
  return customerCostPerVehicle * numberOfVehicles
}

export function calculateBoosterFleetPricing(
  visitFee: number,
  vehicleFee?: number,
  numberOfVehicles?: number
) {
  return vehicleFee != null && numberOfVehicles != null
    ? visitFee + vehicleFee * numberOfVehicles
    : visitFee
}

export function calculateBoosterFleetSavings(
  boosterVehicleSavings: number,
  numberOfVehicles: number
) {
  return boosterVehicleSavings * numberOfVehicles
}

export function calculateBoosterFleetTotalSavings(
  boosterVehicleTotalSavings: number,
  numberOfVehicles: number
) {
  return boosterVehicleTotalSavings * numberOfVehicles
}

export function calculateCustomerWeeklyCost(
  customerFleetCost: number,
  gasStationTripsPerWeek: number
) {
  return customerFleetCost * gasStationTripsPerWeek
}

export function calculateBoosterWeeklyPricing(
  boosterFleetPricing: number,
  numberOfDeliveries: number
) {
  return boosterFleetPricing * numberOfDeliveries
}

export function calculateBoosterWeeklySavings(
  boosterFeetSavings: number,
  numberOfDeliveries: number
) {
  return boosterFeetSavings * numberOfDeliveries
}

export function calculateBoosterWeeklyTotalSavings(
  customerWeeklyCost: number,
  boosterWeeklyPricing: number,
  boosterWeeklySavings: number
) {
  return customerWeeklyCost - (boosterWeeklyPricing - boosterWeeklySavings)
}

export function calculateCustomerAnnualCost(customerWeeklyCost: number) {
  return customerWeeklyCost * 52
}

export function calculateBoosterAnnualPricing(boosterWeeklyPricing: number) {
  return boosterWeeklyPricing * 52
}

export function calculateBoosterAnnualSavings(boosterWeeklySavings: number) {
  return boosterWeeklySavings * 52
}

export function calculateBoosterAnnualTotalSavings(
  boosterWeeklyTotalSavings: number
) {
  return boosterWeeklyTotalSavings * 52
}

export function calculateTotalAnnualHours(
  tripsPerYear: number,
  timeToAtFromGasStation: number,
  numberOfVehicles: number,
  employeesPerVehicle = 1
) {
  return (
    (tripsPerYear *
      timeToAtFromGasStation *
      numberOfVehicles *
      employeesPerVehicle) /
    60
  )
}
